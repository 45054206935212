import axios from 'axios'

export default {
    get: () => axios.get('suppliers'),
    getByManager: (manager_id) => axios.get('suppliers/by-manager/'+manager_id),
    store: (data) => axios.post('suppliers/store', data),
    update: (data) => axios.post('suppliers/update', data),
    upgrade: (data) => axios.post('suppliers/upgrade', data),
    delete: (id) => axios.post('suppliers/delete', id),
    import: (data) => axios.post('suppliers/import', data),
    generateLink: (data) => axios.post('suppliers/generate-link', data),
    getByChallenge: (challenge_id) => axios.get('challenges/'+challenge_id+'/suppliers'),
    getPoints: () => axios.get('suppliers/all-points'),
    getExcelPoints: () => axios.get('suppliers/all-points-excel'),
    getActives: () => axios.get('suppliers/actives'),
    getExcelActives: () => axios.get('suppliers/actives-excel'),
    updatePoints: (data) => axios.post('suppliers/update-points', data),
}