<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CustomersTable
          :items="customers"
          hover
          striped
          border
          small
          fixed
          caption="Clientes"
          @refresh="refreshItems"
          :permissions="permissions"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CustomersTable from '../../components/customers/CustomersTable.vue'
import ws from '../../services/customers';
import account_ws from '../../services/account';
import store from '../../store'

export default {
  name: 'Customers',
  components: { CustomersTable },
  data: function () {
		return {
            customers: [],
            permissions: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.customers = response.data;
    }

    let response2 = await account_ws.getInfo(); 

    if(response2.type == "success"){
      this.permissions = response2.data.user.permissions;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.customers = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
